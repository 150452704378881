.circle {
    height: 30px;
    width: 30px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    color: #01426A;
    font-weight: bold;
    border: 3px solid #01426A;
    padding-left: 5px;
    padding-right: 5px;
  }

.grid {
    padding: 8px;
    box-shadow: 1px 1px 1px 1px gray;
    border-radius: 3px;
    cursor: pointer;
    background: "#C7A000";
    margin-bottom: 10px;

  }

.grid:hover{
    background-color: "#C7A000";
}

  